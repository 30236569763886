class LeseexemplarLoginstatus extends HTMLElement {
    connectedCallback() {
        this.initLoginChangeListener();
    }

    initLoginChangeListener() {
        window.addEventListener('login.change', this.fetchBuchhaendlerCookie, false);
    }

    async fetchBuchhaendlerCookie(event) {
        if (!event.detail || !event.detail.hasOwnProperty('neuerStatus')) {
            console.warn('Das login.change Event hat kein Attribut "neuerStatus"');
            return;
        }
        if (event.detail.neuerStatus !== 'eingeloggt') {
            return;
        }

        navigator.sendBeacon('/leseexemplar/api/rest/buchhaendler/cookie');
    }

}

if (!customElements.get("leseexemplar-loginstatus")) {
    customElements.define("leseexemplar-loginstatus", LeseexemplarLoginstatus);
}

(function (doc) {
    doc.body.insertAdjacentHTML("beforeend", '<leseexemplar-loginstatus></leseexemplar-loginstatus>');
})(document);
